<template>
  <b-overlay :show="isFetching">
    <b-row>
      <b-col sm="5">
        <b-form-group 
            label-cols-sm="6"
            label-cols-lg="4"
            label="ค้นหา ยูสเซอร์เนม">
          <b-input v-model="search"/>
        </b-form-group>
      </b-col>
      <b-col>
        <b-button @click="fetchData">
          <i class="uil uil-search"></i>
          ค้นหา
        </b-button>
      </b-col>
    </b-row>
    <b-table
      :items="filteredList"
      :fields="fields"
      responsive
      show-empty
    >
      <template #cell(#)="data">
        {{ data.index + 1 }}
      </template>
      <template #cell(register_date)="data">
        {{ data.value | datetime }}
      </template>
      <template #cell(username)="data">
        <a :href="`/players/detail/${data.item.id}`">{{ data.value }}</a>
      </template>
      <template #cell(affiliate_by_username)="data">
        {{ data.value }}
      </template>
      <template #cell(total_click)="data">
        {{ data.value  }}
      </template>
      
      <template #empty="">
        <p class="text-center text-muted">{{
            $t('messages.nothing_here')
          }}</p>
      </template>
    </b-table>
    <b-row align-v="center" class="mb-3">
      <b-col>
        <PaginationInput :per-page="limit" :total="playerAfMembers.total" @update="(val) => currentPage = val"/>
      </b-col>
    </b-row>
  </b-overlay>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
export default {
  name: 'PlayerAfMember',
  props: {
    playerId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      limit: 10,
      currentPage: 1,
      search: '',
      fields: [
        '#',
        {
          key: 'register_date',
          label: 'วันที่สมัคร',
          thStyle: {
            minWidth: '130px',
          },
        },
        {
          key: 'username',
          label: 'ยูสเซอร์เนม',
          thStyle: {
            minWidth: '250px',
          },
        },
        {
          key: 'affiliate_by_username',
          label: 'คนเชิญ',
          thStyle: {
            minWidth: '130px',
          },
        },
        {
          key: 'total_click',
          label: 'จำนวนคลิกทั้งหมด',
          thStyle: {
            minWidth: '130px',
          },
        },
        // {
        //   key: 'id',
        //   label: '-',
        //   width: '100',
        //   class: 'text-right',
        // },
      ],
    }
  },
  computed: {
    ...mapState({
      isFetching: (state) => state.player.isFetchingPlayerAfMembers,
    }),
    ...mapGetters(['playerAfMembers']),
    filteredList() {
      return this.playerAfMembers?.data || []
    },
  },
  watch: {
    currentPage(val) {
      if (val) {
        this.fetchData()
      }
    },
  },
  created() {
    this.fetchData()
  },
  methods: {
    ...mapActions(['fetchPlayerAfMembers']),
    fetchData() {
      if(this.playerId){
        this.fetchPlayerAfMembers({
          playerId: this.playerId, 
          currentPage: this.currentPage, 
          limit: this.limit, 
          search: this.search
        })
      }
    },
  },
}
</script>
